<template>
    <div class="PowerList">

        <transition name="van-fade">
            <scroll v-if="!isLoading&&powerList.length>0" class="wrapper" ref="scroll" :pullUpLoad="pullUpLoadObj" :pullDownRefresh="pullDownRefreshObj" @pullingDown="onPullingDown" @pullingUp="onPullingUp">
                <ul class="powerListBox">
                    <li class="powerItem" v-for="(row,index) in powerList" :key="index" :class="{'van-hairline--bottom':index!=powerList.length-1}">
                        <i class="typeIcon iconfont" :class="objectType(row[0].objectType)"></i>
                        <div class="objectName"> {{row[0].objectName}}</div>
                        <!-- '目录管理员' -->
                        <div class="objectType">{{row[0].rightValue==1?$t('mx_mobile.Doc.1585198001130'):''}}</div>
                        <i class="setttingIcon iconfont icon-setting" @click="setPower(row)"></i>
                        <i class="delIcon iconfont icon-del-light" @click="deletePower(row[0])"></i>
                    </li>
                </ul>
            </scroll>
        </transition>
        <transition name="van-fade">
            <nodata v-if="this.powerList.length<=0&&!isLoading">
            </nodata>
        </transition>
        <transition name="van-fade">
            <loading v-if="isLoading" size="30px"></loading>
        </transition>

    </div>
</template>
<script>
import titleMixin from '@/mixin/title'
import Scroll from '@/components/Scroll/index'
export default {
    name: 'PowerList',
    mixins: [titleMixin],
    title: function fc() { return this.$t('mx_mobile.Doc.1585198113947') }, // '权限设置'
    data() {
        return {
            folderId: '',
            powerList: [],
            persons: [],
            depts: [],
            roles: [],
            page: {
                total: 0,
                pageN: 0,
                pageSize: 10
            },
            isLoading: false,

            pullDownRefresh: true,
            pullDownRefreshThreshold: 60,
            pullDownRefreshStop: 60,
            pullUpLoad: true,
            pullUpLoadThreshold: 20,
            pullUpLoadMoreTxt: this.$t('mx_mobile.Doc.1585194359043'), // '加载更多'
            pullUpLoadNoMoreTxt: this.$t('mx_mobile.Doc.1585194375114') //  '没有更多数据了'
        }
    },
    mounted() {
        this.pageInit()
    },
    computed: {
        pullDownRefreshObj: function () {
            return this.pullDownRefresh ? {
                threshold: parseInt(this.pullDownRefreshThreshold),
                stop: parseInt(this.pullDownRefreshStop)
            } : false
        },
        pullUpLoadObj: function () {
            return this.pullUpLoad
                ? {
                    threshold: parseInt(this.pullUpLoadThreshold),
                    txt: { more: this.pullUpLoadMoreTxt, noMore: this.pullUpLoadNoMoreTxt }
                }
                : false
        }
    },
    methods: {
        setMenu() {
            let menu = this.Global.utils.rightMenu.setMenu(this.$t('mx_mobile.Doc.1585198311261')) // '更多'
            menu.onClick(() => {
                this.moreOptionShow()
            })
        },
        objectType(code) {
            switch (code) {
                case 1:
                    /* 人员 */
                    return 'icon-person'
                case 2:
                    /* 部门 */
                    return 'icon-department'
                case 3:
                    /* 角色 */
                    return 'icon-role'
                default:
                    return ''
            }
        },
        async moreOptionShow() {
            try {
                let options = [
                    { name: this.$t('mx_mobile.Doc.1585198533666'), index: 0 }, // '添加人员'
                    { name: this.$t('mx_mobile.Doc.1585198549374'), index: 1 }, // '添加部门'
                    { name: this.$t('mx_mobile.Doc.1585198572923'), index: 2 } // '添加角色'
                ]
                let res = await this.Global.utils.actionSheet.open(options)
                switch (res.index) {
                    case 0:
                        this.choosePerson()
                        break
                    case 1:
                        this.chooseDept()
                        break
                    case 2:
                        this.chooseRole()
                        break
                    default:
                        break
                }
            } catch (error) {
                console.log(error)
            }
        },
        setPower(row) {
            /* TODO:设置权限 */
            this.$router.push({ name: 'PowerSetting', params: { row }, query: this.g_setQuery() })
        },
        async deletePower(item) {
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.folders_folderRights
                let data = {
                    folderId: item.folderId,
                    objectId: item.objectId,
                    objectType: item.objectType
                }
                let res = await this.axios.delete(url, { data })

                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.getPowerList()
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {
                console.log(error)
            }
        },
        async choosePerson() {
            try {
                if (this.persons.length <= 0) {
                    await this.getPerson()
                }
                let canSel = []
                this.persons.forEach(item => {
                    canSel.push({ text: item.realName, value: item.companies[0].ctId })
                })
                let data = await this.Global.utils.chosen.open(canSel, canSel[0]['text'])
                this.addPowerItem({
                    objectId: data.value,
                    objectType: 1
                })
            } catch (error) {
                console.log(error)
            }
        },
        async chooseDept() {
            try {
                if (this.depts.length <= 0) {
                    await this.getDept()
                }
                let canSel = []
                this.depts.forEach(item => {
                    canSel.push({ text: item.deptName, value: item.dkey })
                })
                let data = await this.Global.utils.chosen.open(canSel, canSel[0]['text'])

                this.addPowerItem({
                    objectId: data.value,
                    objectType: 2
                })
            } catch (error) {
                console.log(error)
            }
        },
        async chooseRole() {
            try {
                if (this.roles.length <= 0) {
                    await this.getRole()
                }
                let canSel = []
                this.roles.forEach(item => {
                    canSel.push({ text: item.roleName, value: item.roleId })
                })

                let data = await this.Global.utils.chosen.open(canSel, canSel[0]['text'])

                this.addPowerItem({
                    objectId: data.value,
                    objectType: 3
                })
            } catch (error) {
                console.log(error)
            }
        },
        async addPowerItem(obj) {
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.folders_folderRights
                let data = {
                    folderId: this.folderId,
                    ...obj
                }
                let res = await this.axios.post(url, data)
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.getPowerList()
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {
                console.log(error)
            }
        },
        /* 获取所有员工 */
        async getPerson() {
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.account_query
                let params = {
                    type: 'allUserList'
                }
                let res = await this.axios.get(url, { params })
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.persons = res.data.data.accountList
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {
                console.log(error)
            }
        },
        /* 获得所有部门 */
        async getDept() {
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.department + '0'
                let res = await this.axios.get(url)
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.depts = res.data.data
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {
                console.log(error)
            }
        },
        /* 获取所有角色 */
        async getRole() {
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.roles + 'get'
                let params = {
                    type: 'all'
                }
                let res = await this.axios.get(url, { params })

                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.roles = res.data.data
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {
                console.log(error)
            }
        },
        async getPowerList() {
            this.isLoading = true
            this.page.pageN = 1
            this.page.total = 0
            this.powerList = []
            this.powerList = await this.getData()
            this.isLoading = false
        },
        async getData() {
            let list = []
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.folders_folderRights
                let params = {
                    folderId: this.folderId,
                    pageN: this.page.pageN,
                    pageSize: this.pageSize
                }
                let res = await this.axios.get(url, { params })

                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    list = res.data.data.list
                    this.page.total = res.data.data.totalNum
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {
                console.log(error)
            }
            return list
        },
        async onPullingDown() {
            this.page.pageN = 1
            this.powerList = await this.getData()
            if (this._isDestroyed) {
                return
            }
            this.$nextTick(() => {
                this.$refs.scroll.forceUpdate()
            })
        },
        async onPullingUp() {
            if (this.powerList.length >= this.page.total) {
                this.$refs.scroll.forceUpdate(false)
                return
            }
            if (this.powerList.length < this.page.total) {
                this.page.pageN += 1
                let list = await this.getData()
                this.powerList = this.powerList.concat(list)
                this.$nextTick(() => {
                    if (this.powerList.length >= this.page.total) {
                        this.$refs.scroll.forceUpdate(false)
                    } else {
                        this.$refs.scroll.forceUpdate(true)
                    }
                })
            }
        },
        pageInit() {
            this.setMenu()
            let { folderId } = this.$route.query
            this.folderId = folderId
            this.getPowerList()
        }
    },
    beforeRouteLeave(to, from, next) {
        const flag = this.Global.utils.chosen.close()
        const flag2 = this.Global.utils.actionSheet.close()
        if (flag || flag2) {
            next(false)
        } else {
            next()
        }
    },
    watch: {
        $route(to, from) {
            if (to.path == '/document/powerlist') {
                this.pageInit()
            }
        },
        pullDownRefreshObj: {
            handler(val) {
                const scroll = this.$refs.scroll.scroll
                if (val) {
                    scroll.openPullDown()
                } else {
                    scroll.closePullDown()
                }
            },
            deep: true
        },
        pullUpLoadObj: {
            handler(val) {
                const scroll = this.$refs.scroll.scroll
                if (val) {
                    scroll.openPullUp()
                } else {
                    scroll.closePullUp()
                }
            },
            deep: true
        }
    },
    components: {
        'scroll': Scroll
    }
}
</script>
<style lang='less' rel='stylesheet/less' scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
